import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { PageProps, graphql } from 'gatsby'
import ProjectCard from '../components/project-card'

import { ProjectImageT, ProjectVideoT } from '../common'
import { SVG, easing } from '@svgdotjs/svg.js'
import useElementSize from '../hooks/useElementSize'
import getAbsoluteOffset from '../utils/getAbsoluteOffset'
import useHeaderLines from '../hooks/useHeaderLines'

type Data = {
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: {
          title: string
          video: ProjectVideoT
          images: Array<ProjectImageT>
          slug: string
          info: {
            area: string
          }
        }
      }
    }[]
  }
}

const Projects: React.FC<PageProps<Data>> = ({ data, location }) => {
  const projects = data.allMarkdownRemark.edges
  const pageRef = React.useRef(null)
  const size = useElementSize(pageRef)
  const headerLines = useHeaderLines()

  React.useEffect(() => {
    if (pageRef.current && size) {
      const headerRect = getAbsoluteOffset(document.querySelector('.header'))
      const pageRect = getAbsoluteOffset(
        document.querySelector('.page--projects'),
      )
      const breadcrumbRect = getAbsoluteOffset(
        document.querySelector('.breadcrumb-container'),
      )
      const firstProjectRect = getAbsoluteOffset(
        document.querySelector('.projects > .project-card:first-child'),
      )

      const svg = SVG()
        .addClass('topline')
        .addTo('body')
        .size('100%', document.body.clientHeight)
        .stroke({
          color: '#000',
          width: 5,
          miterlimit: 10,
        })

      const polyline = svg
        .polyline([
          ...headerLines,

          pageRect.left,
          headerRect.bottom,

          pageRect.left,
          breadcrumbRect.bottom,

          pageRect.right,
          breadcrumbRect.bottom,

          pageRect.right,
          firstProjectRect.bottom,

          document.body.clientWidth,
          firstProjectRect.bottom,
        ])
        .stroke({
          width: 0,
        })
      const polylineLength = polyline.node.getTotalLength()
      polyline
        .attr({
          'stroke-width': 5,
          'stroke-dashoffset': polylineLength,
          'stroke-dasharray': polylineLength,
        })
        .animate({ duration: 3000, delay: 0 })
        .ease(easing['<>'])
        .attr({
          'stroke-dashoffset': 0,
        })

      return () => svg.remove()
    }
  }, [size])

  return (
    <Layout
      breadcrumbs={[
        { name: 'Projects', to: '/projects', color: '#ff4b3d' },
        { name: 'Workplace Design', color: '#a59088' },
      ]}
    >
      <SEO title="Projects" />
      <main ref={pageRef} className={`container page--projects`}>
        <div className="projects">
          {projects.map((project: any, index: number) => {
            const {
              slug,
              title,
              images,
              video,
              info,
            } = project.node.frontmatter
            const mainImage = images.filter(
              (i: ProjectImageT) => i.featured === true,
            )
            return (
              <ProjectCard
                // video={video && video[0].id}
                image={mainImage[0].url}
                title={title}
                info={info}
                url={slug}
                key={index}
              />
            )
          })}
        </div>
      </main>
    </Layout>
  )
}

export default Projects

export const pageQuery = graphql`
  query blogPageQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(projects)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            video {
              id
              featured
            }
            images {
              url
              featured
              alt
            }
            info {
              area
            }
          }
        }
      }
    }
  }
`
